"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loginRootReducer = void 0;
var redux_1 = require("redux");
var user_reducer_1 = require("./user/user.reducer");
var common_reducer_1 = require("./common/common.reducer");
exports.loginRootReducer = (0, redux_1.combineReducers)({
    userStore: user_reducer_1.userReducer,
    commonStore: common_reducer_1.CommonReducer
});
