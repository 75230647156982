{
  "common": {
    "administration": "Verwaltung",
    "projects": "Projekte",
    "quickAccess": "VPS Schnellzugriff",
    "editProfile": "Profil bearbeiten",
    "settings": "Einstellungen",
    "logout": "Abmelden",
    "editLayout": "Layout bearbeiten",
    "options": "Optionen"
  },
  "settings": {
    "username": "Nutzername",
    "email": "E-Mail-Adresse",
    "name": "Klartextname",
    "role": "Rolle",
    "phone": "Telefon",
    "customer": "Kunde",
    "changePassword": "Passwort ändern",
    "oldPassword": "Altes Passwort",
    "newPassword": "Neues Passwort",
    "confirmPassword": "Neues Passwort wiederholen"
  },
  "login": {
    "welcome": "Willkommen bei Professocial!",
    "forgotPassword": "PASSWORT VERGESSEN?",
    "login": "LOGIN",
    "password": "Passwort",
    "username": "Benutzername",
    "register": "NOCH KEIN ACCOUNT? JETZT REGISTRIEREN!",
    "registrationForm": "Registrieren",
    "cancel": "zurück zum Login",
    "passwordReset": "Passwort anfordern",
    "sendRegistration": "Registrierung abschicken",
    "authentication": "Authentifizierung",
    "secretCode": "Sicherheitscode",
    "deviceName": "Gerätename",
    "secretCodes": "Sicherheitscodes",
    "twoFactorAuth": "Zwei-Faktor-Authentifizierung",
    "registerInAuthApp": "Registrieren Sie jetzt eine Authenticator App.",
    "enterCode": "Geben Sie den ersten Code zur Bestätigung ein.",
    "device": "Bitte wählen Sie einen Gerätenamen aus.",
    "activate": "Aktivieren",
    "secretManagement": "Secret-Verwaltung",
    "registrationTitle": "Registrieren Sie sich jetzt in wenigen Schritten für den Facebook-Baukasten",
    "enterCaptcha": "Geben Sie das obige Wort ein",
    "captchaFailed": "Das eingebene Sicherheitswort ist leider falsch. Versuchen Sie es bitte erneut!",
    "resetPassword": "Passwort zurücksetzen",
    "resetPasswordClose": "Schließen",
    "resetPasswordSuccessHeadline": "Vielen Dank!",
    "resetPasswordSuccessHeadText": "Wir überprüfen kurz, ob Ihre E-Mail-Adresse stimmt. Wenn das geklappt hat, erhalten Sie in wenigen Minuten eine E-Mail, mit der Sie das Passwort zurücksetzen können.<br/><br/> Bitte schauen Sie in Ihr Postfach und klicken Sie in der E-Mail auf den Link, um ein neues Passwort festzulegen.",
    "passwordInfo": "Das Kennwort muss 10 bis 20 Zeichen lang sein, aus Groß- und Kleinbuchstaben sowie Ziffern und wenigstens einem Sonderzeichen (@#$%_-) bestehen.",
    "passwordSave": "Passwort speichern",
    "tokenInvalidErrorHeader": "Link abgelaufen!",
    "tokenInvalidError": "Bitte kontrollieren Sie den Link in Ihrem Browser auf Vollständigkeit.",
    "tokenAcceptedHeader": "Ihr Passwort wurde zurückgesetzt!",
    "tokenAccepted": "Bitte loggen Sie sich jetzt mit dem neuen Passwort ein.",
    "loginError:": "Login fehlgeschlagen! Bitte überprüfen Sie Ihre Logindaten!"
  },
  "facebookSettingsCommon": {
    "templateLong": "Willkommen auf unserer Facebook-Seite!\n\nWir sind dein ERGO Partner für Versicherung und Vorsorge.\nWir sind an deiner Seite, wenn es um qualifizierte und verständliche Beratung geht und bieten dir umfassende Betreuung und schnelle Schadensregulierung.\nHast du Fragen zu unserem Produkt- und Serviceangebot? Kontaktiere uns gern.\n\nDein ERGO Partner\n[vorname] [nachname].\n\nMehr Informationen zum Team unter: http://www.[vorname]-[nachname].ergo.de/de",
    "imprintTemplate": "Sie haben sich im Auftrag des Agenturinhabers mit Ihrem Namen eingeloggt. Gehen Sie bitte wie folgt vor:\n\n- Haken links unten bei \"Impressum automatisch aktualisieren\" deaktivieren\n- Adresse zum Impressum des Agenturinhabers eintragen, auf dessen Namen die Agenturhomepage läuft:\n'https://[Vorname]-[Nachname].ergo.de/de/Agentur/impressum'\n- Haken deaktiviert lassen und Vorgang mit speichern abschließen"
  }
}
