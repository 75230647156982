"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.userReducer = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var immer_1 = require("immer");
var user_actions_1 = require("./user.actions");
var INITIAL_STATE = {
    fetching: false,
    onRegistration: false,
    isLogged: false,
    error: {},
    success: {},
};
var triggerProfessocialMessage = function (data) {
    window.dispatchEvent(new CustomEvent('ProfessocialMessage', { detail: data }));
};
exports.userReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    /**
     * userLogin
     */
    .case(user_actions_1.userLogin.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.userLogin;
    });
})
    .case(user_actions_1.userLogin.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.isLogged = true;
        result.id && window.localStorage.setItem('user', result.id);
        window.dispatchEvent(new CustomEvent('ProfessocialUser', { detail: result }));
        draft.user = result;
        draft.fetching = false;
    });
})
    .case(user_actions_1.userLogin.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        var errorStatus = error;
        if (errorStatus.status === 401) {
            draft.isLogged = false;
            draft.user = undefined;
        }
        draft.error.userLogin = errorStatus;
    });
})
    /**
     * restPasswordForUser
     */
    .case(user_actions_1.restPasswordForUser.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.restPasswordForUser;
    });
})
    .case(user_actions_1.restPasswordForUser.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(user_actions_1.restPasswordForUser.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.restPasswordForUser = error;
    });
})
    /**
     * registerUserAccount
     */
    .case(user_actions_1.registerUserAccount.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.onRegistration = true;
        delete draft.error.registerUserAccount;
    });
})
    .case(user_actions_1.registerUserAccount.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.isLogged = true;
        draft.user = result;
        draft.fetching = false;
        draft.onRegistration = false;
    });
})
    .case(user_actions_1.registerUserAccount.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.onRegistration = false;
        draft.error.registerUserAccount = error;
    });
})
    /**
       * getNewCaptcha
       */
    .case(user_actions_1.getNewCaptcha.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getNewCaptcha;
    });
})
    .case(user_actions_1.getNewCaptcha.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.captcha = result;
        draft.fetching = false;
    });
})
    .case(user_actions_1.getNewCaptcha.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getNewCaptcha = error;
    });
})
    /**
     * confirmUserAccount
     */
    .case(user_actions_1.confirmUserAccount.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.confirmUserAccount;
    });
})
    .case(user_actions_1.confirmUserAccount.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(user_actions_1.confirmUserAccount.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.confirmUserAccount = error;
    });
})
    /**
     * changePasswordByUser
     */
    .case(user_actions_1.changePasswordByUser.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.changePasswordByUser;
    });
})
    .case(user_actions_1.changePasswordByUser.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(user_actions_1.changePasswordByUser.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.changePasswordByUser = error;
    });
})
    /**
     * Message Management
     */
    .case(user_actions_1.setMessage, function (state, message) {
    // Fix - wait while the reducer is executing
    setTimeout(function () { return triggerProfessocialMessage(message); });
    return (0, immer_1.produce)(state, function (draft) {
        draft.message = message;
    });
})
    .case(user_actions_1.setUser, function (state, user) {
    return (__assign(__assign({}, state), { user: user }));
});
