"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUrlInformation = exports.getPageOwnersData = exports.getPageMasterData = exports.getNetworkContentPage = exports.getDetailPosts = exports.getReloadCalendar = exports.getQuizData = exports.getReNewPageSettings = exports.getCoreInstaPageSettings = exports.getCoreFBPageSettings = exports.getAboSettings = exports.getPostingStatus = exports.getUpdatedPostTemplate = exports.getGlobalPosts = exports.getAboTopics = exports.getTemplates = exports.getCalendarData = exports.getLinkedInPage = exports.getInstagramPage = exports.getInstagramPost = exports.getFacebookPost = exports.getFacebookPage = exports.getDeleteRequestFetching = void 0;
var reselect_1 = require("reselect");
exports.getDeleteRequestFetching = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.deleteRequestFetching; }, function (state) { return state; });
exports.getFacebookPage = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.facebookPage; }, function (state) { return state; });
exports.getFacebookPost = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.facebookPost; }, function (state) { return state; });
exports.getInstagramPost = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.instagramPost; }, function (state) { return state; });
exports.getInstagramPage = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.instagramPage; }, function (state) { return state; });
exports.getLinkedInPage = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.linkedInPage; }, function (state) { return state; });
exports.getCalendarData = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.calendarData; }, function (state) { return state; });
exports.getTemplates = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.templates; }, function (state) { return state; });
exports.getAboTopics = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.aboTopics; }, function (state) { return state; });
exports.getGlobalPosts = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.globalPosts; }, function (state) { return state; });
exports.getUpdatedPostTemplate = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.updatedPostTemplate; }, function (state) { return state; });
exports.getPostingStatus = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.postingStatus; }, function (state) { return state; });
exports.getAboSettings = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.aboSettings; }, function (state) { return state; });
exports.getCoreFBPageSettings = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.coreFBPageSettings; }, function (state) { return state; });
exports.getCoreInstaPageSettings = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.coreInstaPageSettings; }, function (state) { return state; });
exports.getReNewPageSettings = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.reNewPageSettings; }, function (state) { return state; });
exports.getQuizData = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.selectedMultipleChoiceQuiz; }, function (state) { return state; });
exports.getReloadCalendar = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.reloadCalendar; }, function (state) { return state; });
exports.getDetailPosts = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.detailPosts; }, function (state) { return state; });
exports.getNetworkContentPage = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.networkContentPage; }, function (state) { return state; });
exports.getPageMasterData = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.pageMaster; }, function (state) { return state; });
exports.getPageOwnersData = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.pageOwners; }, function (state) { return state; });
exports.getUrlInformation = (0, reselect_1.createSelector)(function (state) { return state.dashboardStore.pageResource; }, function (state) { return state; });
