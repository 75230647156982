"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountsReducer = exports.SaveSuccess = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var immer_1 = require("immer");
var accounts_actions_1 = require("./accounts.actions");
var SaveSuccess;
(function (SaveSuccess) {
    SaveSuccess["createUserAccount"] = "createUserAccount";
    SaveSuccess["updateUserAccount"] = "updateUserAccount";
    SaveSuccess["deleteUserAccount"] = "deleteUserAccount";
    SaveSuccess["unmountFacebookPageByUser"] = "unmountFacebookPageByUser";
})(SaveSuccess = exports.SaveSuccess || (exports.SaveSuccess = {}));
var INITIAL_STATE = {
    fetching: false,
    error: {},
    isLogged: false,
};
exports.AccountsReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    .case(accounts_actions_1.setOnSuccess, function (state, saveSuccess) {
    return (__assign(__assign({}, state), { saveSuccess: saveSuccess }));
})
    /**
       * createAccounts
       */
    .case(accounts_actions_1.createUserAccount.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        delete draft.error.createUserAccount;
    });
})
    .case(accounts_actions_1.createUserAccount.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        var errorStatus = error;
        if (errorStatus.status === 401) {
            draft.isLogged = false;
            draft.user = undefined;
        }
        draft.error.createUserAccount = error;
    });
})
    /**
       * deleteUserAccount
       */
    .case(accounts_actions_1.deleteUserAccount.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.saveSuccess = null;
        delete draft.error.deleteUserAccount;
    });
})
    .case(accounts_actions_1.deleteUserAccount.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.saveSuccess = SaveSuccess.deleteUserAccount;
        draft.fetching = false;
    });
})
    .case(accounts_actions_1.deleteUserAccount.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        var errorStatus = error;
        if (errorStatus.status === 401) {
            draft.isLogged = false;
            draft.user = undefined;
        }
        draft.error.deleteUserAccount = error;
    });
})
    /**
       * updateUserAccount
       */
    .case(accounts_actions_1.updateUserAccount.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.updateUserAccount;
    });
})
    .case(accounts_actions_1.updateUserAccount.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.saveSuccess = SaveSuccess.updateUserAccount;
        draft.fetching = false;
    });
})
    .case(accounts_actions_1.updateUserAccount.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        var errorStatus = error;
        if (errorStatus.status === 401) {
            draft.isLogged = false;
            draft.user = undefined;
        }
        draft.error.updateUserAccount = error;
    });
})
    /**
       * getAllUsers
       */
    .case(accounts_actions_1.getAllUsers.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getAllUsers;
    });
})
    .case(accounts_actions_1.getAllUsers.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.allUserAccounts = result;
        draft.fetching = false;
    });
})
    .case(accounts_actions_1.getAllUsers.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        var errorStatus = error;
        if (errorStatus.status === 401) {
            draft.isLogged = false;
            draft.user = undefined;
        }
        draft.error.getAllUsers = error;
    });
})
    /**
       * getAllSubsidiaries
       */
    .case(accounts_actions_1.getAllSubsidiaries.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.saveSuccess = null;
        delete draft.error.getAllSubsidiaries;
    });
})
    .case(accounts_actions_1.getAllSubsidiaries.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.allSubsidiaries = result;
        draft.fetching = false;
    });
})
    .case(accounts_actions_1.getAllSubsidiaries.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        var errorStatus = error;
        if (errorStatus.status === 401) {
            draft.isLogged = false;
            draft.user = undefined;
        }
        draft.error.getAllSubsidiaries = error;
    });
})
    /**
       * listSubsidiaries
       */
    .case(accounts_actions_1.listSubsidiaries.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.saveSuccess = null;
        delete draft.error.listSubsidiaries;
    });
})
    .case(accounts_actions_1.listSubsidiaries.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.allSubsidiaries = result;
        draft.fetching = false;
    });
})
    .case(accounts_actions_1.listSubsidiaries.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        var errorStatus = error;
        if (errorStatus.status === 401) {
            draft.isLogged = false;
            draft.user = undefined;
        }
        draft.error.listSubsidiaries = error;
    });
})
    /**
       * unmountFacebookPageByUser
       */
    .case(accounts_actions_1.unmountFacebookPageByUser.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.saveSuccess = null;
        delete draft.error.unmountFacebookPageByUser;
    });
})
    .case(accounts_actions_1.unmountFacebookPageByUser.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.saveSuccess = SaveSuccess.unmountFacebookPageByUser;
        draft.fetching = false;
    });
})
    .case(accounts_actions_1.unmountFacebookPageByUser.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        var errorStatus = error;
        if (errorStatus.status === 401) {
            draft.isLogged = false;
            draft.user = undefined;
        }
        draft.error.unmountFacebookPageByUser = error;
    });
})
    /**
       * loginAs
       */
    .case(accounts_actions_1.loginAs.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.loginAs;
    });
})
    .case(accounts_actions_1.loginAs.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        window.location.replace('/user/settings/connect?login_as=true');
    });
})
    .case(accounts_actions_1.loginAs.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.loginAs = error;
    });
})
    /**
       * getAboSettingsForPageIdentifiers
       */
    .case(accounts_actions_1.getAboSettingsForPageIdentifiers.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getAboSettingsForCurrentUser;
    });
})
    .case(accounts_actions_1.getAboSettingsForPageIdentifiers.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.aboSettings = result;
        draft.fetching = false;
    });
})
    .case(accounts_actions_1.getAboSettingsForPageIdentifiers.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getAboSettingsForPageIdentifiers = error;
    });
})
    /**
       * initiateFacebookConnect
       */
    .case(accounts_actions_1.initiateFacebookConnect.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.performDisconnect;
    });
})
    .case(accounts_actions_1.initiateFacebookConnect.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.facebookConnectHash = result.connectHash;
        draft.fetching = false;
        return draft;
    });
})
    .case(accounts_actions_1.initiateFacebookConnect.failed, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    /**
       * getPageIdentifiersForUser
       */
    .case(accounts_actions_1.getPageIdentifiersForUser.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getAboSettingsForCurrentUser;
    });
})
    .case(accounts_actions_1.getPageIdentifiersForUser.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.pageIdentifiers = result;
        draft.fetching = false;
    });
})
    .case(accounts_actions_1.getPageIdentifiersForUser.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getAboSettingsForPageIdentifiers = error;
    });
})
    /**
     * getFacebookTokenForUserPage
     */
    .case(accounts_actions_1.getTokenForPage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getAboSettingsForCurrentUser;
    });
})
    .case(accounts_actions_1.getTokenForPage.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.token = result;
        draft.fetching = false;
    });
})
    .case(accounts_actions_1.getTokenForPage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getFacebookTokenForUserPage = error;
    });
});
