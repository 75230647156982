"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDateAsString = void 0;
function formatDateAsString(date) {
    if (!date)
        return '';
    var dateObj = new Date(date);
    var options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    };
    return dateObj.toLocaleDateString('de-DE', options); // Using 'de-DE' locale for 'DD.MM.YYYY' format
}
exports.formatDateAsString = formatDateAsString;
