"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roundToTwoDecimalPlaces = void 0;
var types_1 = require("../shared/types/types");
var sm_publish_frontend_api_1 = require("@3m5/sm_publish-frontend-api");
var roundToTwoDecimalPlaces = function (width, height) { return Math.round((width / height) * 100) / 100; };
exports.roundToTwoDecimalPlaces = roundToTwoDecimalPlaces;
var checkRatio = function (width, height, socialMediaNetwork, restrictions) {
    var ratio = (0, exports.roundToTwoDecimalPlaces)(width, height);
    var error = [];
    Object.keys(socialMediaNetwork).map(function (network) {
        var _a, _b;
        var networkType = sm_publish_frontend_api_1.NetworkType.Facebook;
        if (network === 'instagram') {
            networkType = sm_publish_frontend_api_1.NetworkType.Instagram;
        }
        if (network === 'googleMyBusiness') {
            networkType = sm_publish_frontend_api_1.NetworkType.GoogleMyBusiness;
        }
        if (socialMediaNetwork[network] && restrictions[network]) {
            // ratio
            var minAspectRatio = (_a = restrictions[network].image) === null || _a === void 0 ? void 0 : _a.minAspectRatio;
            if (minAspectRatio && ratio < minAspectRatio) {
                error.push({
                    errorCode: types_1.PostingErrorCode.errorImageRatioWrong,
                    network: networkType,
                });
            }
            var maxAspectRatio = (_b = restrictions[network].image) === null || _b === void 0 ? void 0 : _b.maxAspectRatio;
            if (maxAspectRatio && ratio > maxAspectRatio) {
                error.push({
                    errorCode: types_1.PostingErrorCode.errorImageRatioWrong,
                    network: networkType,
                });
            }
        }
    });
    return error;
};
exports.default = checkRatio;
