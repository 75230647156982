"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPublishedTemplatesStatistics = exports.getPublishedPostsExportedStatistics = exports.getPublishedTemplatesExportedStatistics = exports.getAggregatedPublishedTemplateCounts = exports.getAggregatedPublishedPostsInsightsBefore = exports.getAggregatedPublishedPostsInsights = exports.getAggregatedPublishedPostsByTemplateCounts = exports.getPostTemplatesByMetric = exports.getIGExportedStatistics = exports.getFBExportedStatistics = exports.getInstagramPageStatistics = exports.getFacebookPageStatistics = void 0;
var reselect_1 = require("reselect");
exports.getFacebookPageStatistics = (0, reselect_1.createSelector)(function (state) { return state.statisticsEditorStore.facebookPageStatistics; }, function (state) { return state; });
exports.getInstagramPageStatistics = (0, reselect_1.createSelector)(function (state) { return state.statisticsEditorStore.instagramPageStatistics; }, function (state) { return state; });
exports.getFBExportedStatistics = (0, reselect_1.createSelector)(function (state) { return state.statisticsEditorStore.facebookExportedStatistics; }, function (state) { return state; });
exports.getIGExportedStatistics = (0, reselect_1.createSelector)(function (state) { return state.statisticsEditorStore.instagramExportedStatistics; }, function (state) { return state; });
exports.getPostTemplatesByMetric = (0, reselect_1.createSelector)(function (state) { return state.statisticsEditorStore.postTemplatesByMetric; }, function (state) { return state; });
exports.getAggregatedPublishedPostsByTemplateCounts = (0, reselect_1.createSelector)(function (state) { return state.statisticsEditorStore.aggregatedPublishedPostsByTemplateCounts; }, function (state) { return state; });
exports.getAggregatedPublishedPostsInsights = (0, reselect_1.createSelector)(function (state) { return state.statisticsEditorStore.aggregatedPublishedPostsInsights; }, function (state) { return state; });
exports.getAggregatedPublishedPostsInsightsBefore = (0, reselect_1.createSelector)(function (state) { return state.statisticsEditorStore.aggregatedPublishedPostsInsightsBefore; }, function (state) { return state; });
exports.getAggregatedPublishedTemplateCounts = (0, reselect_1.createSelector)(function (state) { return state.statisticsEditorStore.aggregatedPublishedTemplateCounts; }, function (state) { return state; });
exports.getPublishedTemplatesExportedStatistics = (0, reselect_1.createSelector)(function (state) { return state.statisticsEditorStore.aggregatedPublishedTemplateExportedStatistics; }, function (state) { return state; });
exports.getPublishedPostsExportedStatistics = (0, reselect_1.createSelector)(function (state) { return state.statisticsEditorStore.aggregatedPublishedPostsExportedStatistics; }, function (state) { return state; });
exports.getPublishedTemplatesStatistics = (0, reselect_1.createSelector)(function (state) { return state.statisticsEditorStore.publishedTemplatesStatistics; }, function (state) { return state; });
