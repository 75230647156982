"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardReducer = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var immer_1 = require("immer");
var dashboard_actions_1 = require("./dashboard.actions");
var types_1 = require("@professocial/professocialPosting/src/shared/types/types");
var INITIAL_STATE = {
    fetching: false,
    deleteRequestFetching: false,
    detailPosts: { Facebook: {}, Instagram: {} },
    reloadCalendar: false,
    reNewPageSettings: false,
    networkContentPage: 1,
    error: {},
};
exports.DashboardReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    .case(dashboard_actions_1.setNetworkContentPage, function (state, networkContentPage) {
    return (__assign(__assign({}, state), { networkContentPage: networkContentPage }));
})
    .case(dashboard_actions_1.setFacebookPage, function (state, facebookPage) {
    return (__assign(__assign({}, state), { facebookPage: facebookPage }));
})
    .case(dashboard_actions_1.setIGPage, function (state, instagramPage) {
    return (__assign(__assign({}, state), { instagramPage: instagramPage }));
})
    /**
       * loadFacebookPage
       */
    .case(dashboard_actions_1.loadFacebookPage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.loadFacebookPage;
    });
})
    .case(dashboard_actions_1.loadFacebookPage.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft = __assign(__assign({}, draft), { facebookPage: result });
        draft.fetching = false;
        return draft;
    });
})
    .case(dashboard_actions_1.loadFacebookPage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.loadFacebookPage = error;
    });
})
    /**
       * getIgPage
       */
    .case(dashboard_actions_1.getIgPage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getIgPage;
    });
})
    .case(dashboard_actions_1.getIgPage.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.instagramPage = __assign({}, result);
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.getIgPage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getIgPage = error;
    });
})
    /**
     * getLinkedInPage
     */
    .case(dashboard_actions_1.getLinkedInPage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getLinkedInPage;
    });
})
    .case(dashboard_actions_1.getLinkedInPage.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.linkedInPage = __assign({}, result);
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.getLinkedInPage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getLinkedInPage = error;
    });
})
    /**
       * deleteCoreFBPageSettings
       */
    .case(dashboard_actions_1.deleteCoreFBPageSettings, function (state) {
    return (__assign(__assign({}, state), { coreFBPageSettings: undefined }));
})
    /**
       * getCoreFBPageSettings
       */
    .case(dashboard_actions_1.getCoreFBPageSettings.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.coreFBPageSettings = undefined;
        delete draft.error.getCoreFBPageSettings;
    });
})
    .case(dashboard_actions_1.getCoreFBPageSettings.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.coreFBPageSettings = result;
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.getCoreFBPageSettings.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getCoreFBPageSettings = error;
    });
})
    /**
       * deleteCoreInstaPageSettings
       */
    .case(dashboard_actions_1.deleteCoreInstaPageSettings, function (state) {
    return (__assign(__assign({}, state), { coreInstaPageSettings: undefined }));
})
    /**
       * getCoreInstaPageSettings
       */
    .case(dashboard_actions_1.getCoreInstaPageSettings.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.coreInstaPageSettings = undefined;
        delete draft.error.getCoreFBPageSettings;
    });
})
    .case(dashboard_actions_1.getCoreInstaPageSettings.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.coreInstaPageSettings = result;
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.getCoreInstaPageSettings.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getCoreFBPageSettings = error;
    });
})
    /**
       * getCalendarData
       */
    .case(dashboard_actions_1.getCalendarData.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getCalendarData;
    });
})
    .case(dashboard_actions_1.getCalendarData.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.calendarData = __assign({}, result);
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.getCalendarData.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getCalendarData = error;
    });
})
    /**
       * findPostTemplatesForCurrentUser
       */
    .case(dashboard_actions_1.findPostTemplatesForCurrentUser.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.findPostTemplatesForCurrentUser;
    });
})
    .case(dashboard_actions_1.findPostTemplatesForCurrentUser.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.templates = result;
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.findPostTemplatesForCurrentUser.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.findPostTemplatesForCurrentUser = error;
    });
})
    /**
       * getTopicsAndMetadataForCurrentUser
       */
    .case(dashboard_actions_1.getTopicsAndMetadataForCurrentUser.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getTopicsAndMetadataForCurrentUser;
    });
})
    .case(dashboard_actions_1.getTopicsAndMetadataForCurrentUser.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.aboTopics = result;
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.getTopicsAndMetadataForCurrentUser.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getTopicsAndMetadataForCurrentUser = error;
    });
})
    /**
       * getAboSettingsForCurrentUser
       */
    .case(dashboard_actions_1.getAboSettingsForCurrentUser.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getAboSettingsForCurrentUser;
    });
})
    .case(dashboard_actions_1.getAboSettingsForCurrentUser.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.aboSettings = result;
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.getAboSettingsForCurrentUser.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getAboSettingsForCurrentUser = error;
    });
})
    /**
       * updateAboSettingsForCurrentUser
       */
    .case(dashboard_actions_1.updateAboSettingsForCurrentUser.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.updateAboSettingsForCurrentUser;
    });
})
    .case(dashboard_actions_1.updateAboSettingsForCurrentUser.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.updateAboSettingsForCurrentUser.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.updateAboSettingsForCurrentUser = error;
    });
})
    /**
       * updatePostTemplate
       */
    .case(dashboard_actions_1.updatePostTemplate.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.postingStatus = types_1.PostingStatus.pending;
        delete draft.error.updatedPostTeplate;
    });
})
    .case(dashboard_actions_1.updatePostTemplate.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.postingStatus = types_1.PostingStatus.success;
        draft.updatedPostTemplate = result;
    });
})
    .case(dashboard_actions_1.updatePostTemplate.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.postingStatus = types_1.PostingStatus.error;
        draft.error.updatedPostTemplate = error;
    });
})
    /**
       * findGlobalPostsForPostTemplate
       */
    .case(dashboard_actions_1.findGlobalPostsForPostTemplate.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.findGlobalPostsForPostTemplate;
    });
})
    .case(dashboard_actions_1.findGlobalPostsForPostTemplate.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.globalPosts = result;
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.findGlobalPostsForPostTemplate.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.findGlobalPostsForPostTemplate = error;
    });
})
    /**
       * getFacebookPost
       */
    .case(dashboard_actions_1.getFacebookPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getFacebookPost;
    });
})
    .case(dashboard_actions_1.getFacebookPost.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.facebookPost = result;
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.getFacebookPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.facebookPost = undefined;
        draft.error.getFacebookPost = error;
    });
})
    /**
       * getInstagramPost
       */
    .case(dashboard_actions_1.getInstagramPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getInstagramPost;
    });
})
    .case(dashboard_actions_1.getInstagramPost.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.instagramPost = result;
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.getInstagramPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getInstagramPost = error;
    });
})
    /**
       * publishPost
       */
    .case(dashboard_actions_1.publishPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.postingStatus = types_1.PostingStatus.pending;
        delete draft.error.publishPost;
        draft.reloadCalendar = false;
    });
})
    .case(dashboard_actions_1.publishPost.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.postingStatus = types_1.PostingStatus.success;
        draft.updatedPostTemplate = result;
        draft.reloadCalendar = true;
    });
})
    .case(dashboard_actions_1.publishPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.postingStatus = types_1.PostingStatus.error;
        draft.error.publishPost = error;
    });
})
    /**
       * deletePostTemplate
       */
    .case(dashboard_actions_1.deletePostTemplate.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.deleteRequestFetching = true;
        delete draft.error.deletePostTemplate;
        draft.reloadCalendar = false;
    });
})
    .case(dashboard_actions_1.deletePostTemplate.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.deleteRequestFetching = false;
        draft.reloadCalendar = true;
    });
})
    .case(dashboard_actions_1.deletePostTemplate.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.deleteRequestFetching = false;
        draft.error.deletePostTemplate = error;
    });
})
    /**
       * declineManualDeferredPost
       */
    .case(dashboard_actions_1.declineManualDeferredPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.declineManualDeferredPost;
        draft.reloadCalendar = false;
    });
})
    .case(dashboard_actions_1.declineManualDeferredPost.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.reloadCalendar = true;
    });
})
    .case(dashboard_actions_1.declineManualDeferredPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.declineManualDeferredPost = error;
    });
})
    /**
       * retryManualDeferredPost
       */
    .case(dashboard_actions_1.retryManualDeferredPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.retryManualDeferredPost;
        draft.reloadCalendar = false;
    });
})
    .case(dashboard_actions_1.retryManualDeferredPost.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.reloadCalendar = true;
    });
})
    .case(dashboard_actions_1.retryManualDeferredPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.retryManualDeferredPost = error;
    });
})
    /**
       * confirmManualDeferredPost
       */
    .case(dashboard_actions_1.confirmManualDeferredPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.confirmManualDeferredPost;
        draft.reloadCalendar = false;
    });
})
    .case(dashboard_actions_1.confirmManualDeferredPost.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.reloadCalendar = true;
    });
})
    .case(dashboard_actions_1.confirmManualDeferredPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.confirmManualDeferredPost = error;
    });
})
    /**
       * deletePost
       */
    .case(dashboard_actions_1.deletePost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.deletePost;
        draft.reloadCalendar = false;
    });
})
    .case(dashboard_actions_1.deletePost.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.reloadCalendar = true;
    });
})
    .case(dashboard_actions_1.deletePost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.deletePost = error;
    });
})
    /**
       * deleteManualDeferredPost
       */
    .case(dashboard_actions_1.deleteManualDeferredPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.deleteManualDeferredPost;
        draft.reloadCalendar = false;
    });
})
    .case(dashboard_actions_1.deleteManualDeferredPost.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.reloadCalendar = true;
    });
})
    .case(dashboard_actions_1.deleteManualDeferredPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.deleteManualDeferredPost = error;
    });
})
    /**
       * updateCorePageSettings
       */
    .case(dashboard_actions_1.updateCorePageSettings.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.reNewPageSettings = false;
        delete draft.error.updateCorePageSettings;
    });
})
    .case(dashboard_actions_1.updateCorePageSettings.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.reNewPageSettings = true;
    });
})
    .case(dashboard_actions_1.updateCorePageSettings.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.updateCorePageSettings = error;
    });
})
    /**
       * getMultipleChoiceQuiz
       */
    .case(dashboard_actions_1.getMultipleChoiceQuiz.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getMultipleChoiceQuiz;
    });
})
    .case(dashboard_actions_1.getMultipleChoiceQuiz.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.multipleChoiceQuiz = result;
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.getMultipleChoiceQuiz.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getMultipleChoiceQuiz = error;
    });
})
    /**
       * getSelectedMultipleChoiceQuiz
       */
    .case(dashboard_actions_1.getSelectedMultipleChoiceQuiz.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getMultipleChoiceQuiz;
    });
})
    .case(dashboard_actions_1.getSelectedMultipleChoiceQuiz.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.selectedMultipleChoiceQuiz = result;
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.getSelectedMultipleChoiceQuiz.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getMultipleChoiceQuiz = error;
    });
})
    /**
       * getPageMaster
       */
    .case(dashboard_actions_1.getPageMaster.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getPageMaster;
    });
})
    .case(dashboard_actions_1.getPageMaster.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.pageMaster = result;
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.getPageMaster.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getPageMaster = error;
    });
})
    /**
       * getPageMaster
       */
    .case(dashboard_actions_1.listPageOwnersForPage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.listPageOwnersForPage;
    });
})
    .case(dashboard_actions_1.listPageOwnersForPage.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.pageOwners = result;
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.listPageOwnersForPage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.listPageOwnersForPage = error;
    });
})
    /**
       * setUserAsPageMaster
       */
    .case(dashboard_actions_1.setUserAsPageMaster.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.setCurrentUserAsPageMaster;
    });
})
    .case(dashboard_actions_1.setUserAsPageMaster.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.setUserAsPageMaster.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.setCurrentUserAsPageMaster = error;
    });
})
    /**
       * getPageSummary
       */
    .case(dashboard_actions_1.getUrlInformation.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getPageSummary;
    });
})
    .case(dashboard_actions_1.getUrlInformation.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.pageResource = result;
        draft.fetching = false;
    });
})
    .case(dashboard_actions_1.getUrlInformation.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getPageSummary = error;
    });
});
