"use strict";
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.userSagas = void 0;
var effects_1 = require("redux-saga/effects");
var typescript_fsa_redux_saga_1 = require("typescript-fsa-redux-saga");
var instances_1 = require("../../api/instances");
var user_actions_1 = require("./user.actions");
var messageTypes_1 = require("@professocial/professocialTypes/types/messageTypes");
var common_actions_1 = require("@professocial/professocialCore/src/store/common/common.actions");
// login
var userLoginWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(user_actions_1.userLogin)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.AutorisationROApi, instances_1.AutorisationROApi.userLogin], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchUserLoginInitiated() {
    var action, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 7];
                return [4 /*yield*/, (0, effects_1.take)(user_actions_1.userLoginInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 6]);
                return [4 /*yield*/, userLoginWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                e_1 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, user_actions_1.setMessage)({
                        severity: messageTypes_1.Severity.error,
                        message: 'Login fehlgeschlagen! Bitte überprüfen Sie Ihre Logindaten!',
                        eventType: messageTypes_1.EventType.login,
                    }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [3 /*break*/, 0];
            case 7: return [2 /*return*/];
        }
    });
}
// restPasswordForUser
var restPasswordForUserWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(user_actions_1.restPasswordForUser)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.AutorisationROApi, instances_1.AutorisationROApi.restPasswordForUser], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchRestPasswordForUserInitiated() {
    var action, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, (0, effects_1.take)(user_actions_1.restPasswordForUserInitiated)];
            case 1:
                action = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestStarted)({ type: action.type }))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                _a.trys.push([3, 6, , 8]);
                return [4 /*yield*/, restPasswordForUserWorker(action.payload)];
            case 4:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFinished)({ type: action.type }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                e_2 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFailed)({ type: action.type, error: e_2 }))];
            case 7:
                _a.sent();
                console.error('error in watchRestPasswordForUserInitiated: ', e_2);
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
// registerUserAccount
var registerUserAccountWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(user_actions_1.registerUserAccount)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.AutorisationROApi, instances_1.AutorisationROApi.registerUserAccount], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchRegisterUserAccountInitiated() {
    var action, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 8];
                return [4 /*yield*/, (0, effects_1.take)(user_actions_1.registerUserAccountInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 5, , 7]);
                return [4 /*yield*/, registerUserAccountWorker(action.payload)];
            case 3:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, user_actions_1.setMessage)({
                        severity: messageTypes_1.Severity.success,
                        message: 'messages:registerUserAccountSuccess',
                        eventType: messageTypes_1.EventType.registerUser,
                    }))];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                e_3 = _a.sent();
                console.error('error in watchRegisterUserAccountInitiated: ', e_3);
                return [4 /*yield*/, (0, effects_1.put)((0, user_actions_1.setMessage)({
                        severity: messageTypes_1.Severity.error,
                        message: 'messages:registerUserAccountError',
                        eventType: messageTypes_1.EventType.registerUser,
                    }))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [3 /*break*/, 0];
            case 8: return [2 /*return*/];
        }
    });
}
// // loginAs
// const loginAsWorker = bindAsyncAction(loginAs)(
//   function * (params: LoginAsRequest) {
//     return yield call([AutorisationROApi, AutorisationROApi.loginAs], params)
//   },
// )
//
// function * watchLoginAsInitiated () {
//   while (true) {
//     const action: Action<LoginAsRequest> = yield take(loginAsInitiated)
//     try {
//       yield loginAsWorker(action.payload)
//     } catch (e) {
//       console.error('error in watchLoginAsInitiated: ', e)
//     }
//   }
// }
// confirmUserAccount
var confirmUserAccountWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(user_actions_1.confirmUserAccount)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.AutorisationROApi, instances_1.AutorisationROApi.confirmUserAccount], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchConfirmUserAccountInitiated() {
    var action, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 7];
                return [4 /*yield*/, (0, effects_1.take)(user_actions_1.confirmUserAccountInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 6]);
                return [4 /*yield*/, confirmUserAccountWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                e_4 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, user_actions_1.setMessage)({
                        severity: messageTypes_1.Severity.error,
                        message: 'messages:confirmUserAccountError',
                        eventType: messageTypes_1.EventType.confirmAccount,
                    }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [3 /*break*/, 0];
            case 7: return [2 /*return*/];
        }
    });
}
// changePasswordByUser
var changePasswordByUserWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(user_actions_1.changePasswordByUser)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.AutorisationROApi, instances_1.AutorisationROApi.changePasswordByUser], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchChangePasswordByUserInitiated() {
    var action, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, (0, effects_1.take)(user_actions_1.changePasswordByUserInitiated)];
            case 1:
                action = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestStarted)({ type: action.type }))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                _a.trys.push([3, 6, , 8]);
                return [4 /*yield*/, changePasswordByUserWorker(action.payload)];
            case 4:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFinished)({ type: action.type }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                e_5 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFailed)({ type: action.type, error: e_5 }))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
// getNewCaptcha
var getNewCaptchaWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(user_actions_1.getNewCaptcha)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.CaptchaRestServiceApi, instances_1.CaptchaRestServiceApi.getNewCaptcha], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchGetNewCaptchaInitiated() {
    var action, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 7];
                return [4 /*yield*/, (0, effects_1.take)(user_actions_1.getNewCaptchaInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 6]);
                return [4 /*yield*/, getNewCaptchaWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                e_6 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, user_actions_1.setMessage)({
                        severity: messageTypes_1.Severity.error,
                        message: 'messages:getNewCaptchaError',
                        eventType: messageTypes_1.EventType.upload,
                    }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [3 /*break*/, 0];
            case 7: return [2 /*return*/];
        }
    });
}
function userSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.fork)(watchUserLoginInitiated)];
            case 1:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchRestPasswordForUserInitiated)];
            case 2:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchRegisterUserAccountInitiated)
                    // yield fork(watchLoginAsInitiated)
                ];
            case 3:
                _a.sent();
                // yield fork(watchLoginAsInitiated)
                return [4 /*yield*/, (0, effects_1.fork)(watchConfirmUserAccountInitiated)];
            case 4:
                // yield fork(watchLoginAsInitiated)
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchChangePasswordByUserInitiated)];
            case 5:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchGetNewCaptchaInitiated)];
            case 6:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.userSagas = userSagas;
