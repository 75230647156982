"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostingReducer = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var immer_1 = require("immer");
var posting_actions_1 = require("./posting.actions");
var types_1 = require("../../shared/types/types");
var INITIAL_STATE = {
    fetching: false,
    loadingTMFile: false,
    postingType: types_1.PostingType.posting,
    error: {},
};
var triggerProfessocialPostingSuccess = function (data) {
    window.dispatchEvent(new CustomEvent('ProfessocialPostingSuccess', { detail: data }));
};
var triggerProfessocialPostingError = function (data) {
    window.dispatchEvent(new CustomEvent('ProfessocialPostingError', { detail: data }));
};
exports.PostingReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    .case(posting_actions_1.setUpdateTemplateId, function (state, updateTemplateId) {
    return (__assign(__assign({}, state), { updateTemplateId: updateTemplateId }));
})
    .case(posting_actions_1.setPostingType, function (state, postingType) {
    return (__assign(__assign({}, state), { postingType: postingType }));
})
    .case(posting_actions_1.setUploadTemporaryMediaFile, function (state, temporaryMediaFile) {
    return (__assign(__assign({}, state), { temporaryMediaFile: temporaryMediaFile }));
})
    /**
     * getAllTags
     */
    .case(posting_actions_1.getAllTags.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getAllTags;
    });
})
    .case(posting_actions_1.getAllTags.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.tags = result;
        draft.fetching = false;
    });
})
    .case(posting_actions_1.getAllTags.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getAllTags = error;
    });
})
    /**
     * getTopicsAndMetadataForCurrentUser
     */
    .case(posting_actions_1.getTopicsAndMetadataForCurrentUser.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getTopicsAndMetadataForCurrentUser;
    });
})
    .case(posting_actions_1.getTopicsAndMetadataForCurrentUser.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.aboTopics = result;
        draft.fetching = false;
    });
})
    .case(posting_actions_1.getTopicsAndMetadataForCurrentUser.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getTopicsAndMetadataForCurrentUser = error;
    });
})
    /**
     * listDeferredPosts
     */
    .case(posting_actions_1.listDeferredPosts.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.listDeferredPosts;
    });
})
    .case(posting_actions_1.listDeferredPosts.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.deferredPosts = result;
        draft.fetching = false;
    });
})
    .case(posting_actions_1.listDeferredPosts.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getAllTags = error;
    });
})
    /**
     * findPostTemplatesForCurrentUser
     */
    .case(posting_actions_1.findPostTemplatesForCurrentUser.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.findPostTemplatesForCurrentUser;
    });
})
    .case(posting_actions_1.findPostTemplatesForCurrentUser.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.templates = result;
        draft.fetching = false;
    });
})
    .case(posting_actions_1.findPostTemplatesForCurrentUser.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.findPostTemplatesForCurrentUser = error;
    });
})
    /**
     * publishPost
     */
    .case(posting_actions_1.publishPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.temporaryMediaFile = undefined;
        delete draft.error.publishPost;
    });
})
    .case(posting_actions_1.publishPost.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        triggerProfessocialPostingSuccess(true);
    });
})
    .case(posting_actions_1.publishPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.publishPost = error;
        triggerProfessocialPostingError(true);
    });
})
    /**
     * createPostTemplate
     */
    .case(posting_actions_1.createPostTemplate.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.temporaryMediaFile = undefined;
        delete draft.error.createPostTemplate;
    });
})
    .case(posting_actions_1.createPostTemplate.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        triggerProfessocialPostingSuccess(true);
    });
})
    .case(posting_actions_1.createPostTemplate.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.createPostTemplate = error;
        triggerProfessocialPostingError(true);
    });
})
    /**
     * updatePostTemplate
     */
    .case(posting_actions_1.updatePostTemplate.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.temporaryMediaFile = undefined;
        delete draft.error.updatedPostTeplate;
    });
})
    .case(posting_actions_1.updatePostTemplate.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.updatedPostTemplate = result;
        triggerProfessocialPostingSuccess(true);
    });
})
    .case(posting_actions_1.updatePostTemplate.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.updatedPostTemplate = error;
        triggerProfessocialPostingError(true);
    });
})
    /**
     * deletePostTemplate
     */
    .case(posting_actions_1.deletePostTemplate.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.deletePostTemplate;
    });
})
    .case(posting_actions_1.deletePostTemplate.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(posting_actions_1.deletePostTemplate.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.deletePostTemplate = error;
    });
})
    /**
     * getMediaFileMetadata
     */
    .case(posting_actions_1.getMediaFileMetadata.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getMediaFileMetadata;
    });
})
    .case(posting_actions_1.getMediaFileMetadata.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.mediaFile = result;
        draft.fetching = false;
    });
})
    .case(posting_actions_1.getMediaFileMetadata.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getMediaFileMetadata = error;
    });
})
    /**
     * uploadMediaFile
     */
    .case(posting_actions_1.uploadMediaFile.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.uploadMediaFile;
    });
})
    .case(posting_actions_1.uploadMediaFile.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(posting_actions_1.uploadMediaFile.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.uploadMediaFile = error;
    });
})
    /**
     * uploadTemporaryMediaFile
     */
    .case(posting_actions_1.uploadTemporaryMediaFile.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.temporaryMediaFile = undefined;
        draft.loadingTMFile = true;
        delete draft.error.uploadTemporaryMediaFile;
    });
})
    .case(posting_actions_1.uploadTemporaryMediaFile.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.temporaryMediaFile = result;
        draft.fetching = false;
        draft.loadingTMFile = result && false;
    });
})
    .case(posting_actions_1.uploadTemporaryMediaFile.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.loadingTMFile = false;
        draft.error.uploadTemporaryMediaFile = error;
    });
})
    /**
     * deleteMediaFile
     */
    .case(posting_actions_1.deleteMediaFile.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.deleteMediaFile;
    });
})
    .case(posting_actions_1.deleteMediaFile.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(posting_actions_1.deleteMediaFile.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.deleteMediaFile = error;
    });
})
    /**
     * listMediaFiles
     */
    .case(posting_actions_1.listMediaFiles.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.listMediaFiles;
    });
})
    .case(posting_actions_1.listMediaFiles.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.mediaFiles = result;
        draft.fetching = false;
    });
})
    .case(posting_actions_1.listMediaFiles.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.listMediaFiles = error;
    });
})
    /**
     * getPageSummary
     */
    .case(posting_actions_1.setUrlInformation, function (state, pageResource) {
    return (__assign(__assign({}, state), { pageResource: pageResource }));
})
    .case(posting_actions_1.getUrlInformation.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getPageSummary;
    });
})
    .case(posting_actions_1.getUrlInformation.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.pageResource = result;
        draft.fetching = false;
    });
})
    .case(posting_actions_1.getUrlInformation.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getPageSummary = error;
    });
})
    /**
     * createTags
     */
    .case(posting_actions_1.createTags.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.createTags;
    });
})
    .case(posting_actions_1.createTags.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        var newTags = draft.tags ? (draft.tags).concat(result) : result;
        draft.tags = newTags;
        draft.fetching = false;
    });
})
    .case(posting_actions_1.createTags.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.createTags = error;
    });
})
    /**
     * updateDeferredPost
     */
    .case(posting_actions_1.updateDeferredPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.updateDeferredPost;
    });
})
    .case(posting_actions_1.updateDeferredPost.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        triggerProfessocialPostingSuccess(true);
    });
})
    .case(posting_actions_1.updateDeferredPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.updateDeferredPost = error;
    });
})
    /**
     * updatePagePost
     */
    .case(posting_actions_1.updatePagePost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.updatePagePost;
    });
})
    .case(posting_actions_1.updatePagePost.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        triggerProfessocialPostingSuccess(true);
    });
})
    .case(posting_actions_1.updatePagePost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.updatePagePost = error;
    });
})
    /**
     * individualizeText
     */
    .case(posting_actions_1.individualizeText.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.individualizeText;
    });
})
    .case(posting_actions_1.individualizeText.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.individualizedText = result;
    });
})
    .case(posting_actions_1.individualizeText.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.individualizeText = error;
    });
})
    /**
     * getDeferredPostById
     */
    .case(posting_actions_1.getDeferredPostById.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getDeferredPostById;
    });
})
    .case(posting_actions_1.getDeferredPostById.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.deferredPostById = result;
    });
})
    .case(posting_actions_1.getDeferredPostById.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getDeferredPostById = error;
    });
});
