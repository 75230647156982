"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatTimeAsString = void 0;
function formatTimeAsString(date) {
    if (!date)
        return '';
    var dateObj = new Date(date);
    var options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false // Ensures 24-hour format
    };
    return dateObj.toLocaleTimeString('de-DE', options);
}
exports.formatTimeAsString = formatTimeAsString;
